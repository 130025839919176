#toggle{
    position: fixed;
    top: 0px;
    right: 0px;
    width: 50px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
#toggle::before{
    content: '';
    position: absolute;
    width: 28px;
    height: 3px;
    background: white;
    transform: translateY(9px);
    transition: 0.2s;
    border-radius: 3px;
}
#toggle::after{
    content: '';
    position: absolute;
    width: 28px;
    height: 3px;
    background: white;
    transform: translateY(-9px);
    transition: 0.2s;
    border-radius: 3px;

}

#line-away{
    content: '';
    position: absolute;
    width: 28px;
    height: 3px;
    background: white;
    transition: 0.8s;
    border-radius: 3px;

}

#toggle.active::before{
    transform: translateY(0px) rotate(45deg);
}

#toggle.active::after{
    transform: translateY(0px) rotate(-45deg);
}

#line-away.none{
    opacity: 0;
}